:root {
  // copied from bootstrap
  --font-sans-serif: system-ui,-apple-system,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans","Liberation Sans";
}

#rules {

  .collapsible {
    background-color: #006533;
    color: #FFF;
    cursor: pointer;
    padding: 18px;
    width: 100%;
    border: none;
    text-align: left;
    outline: none;
    font-size: 2rem;
  }

  #find-us {
    background-color: rgba(0, 0, 0, 0.03);
    padding-top: 1rem;
    padding-bottom: 0.8rem;

    p {
    margin-left: 1rem;
      margin-right: 1rem;
    }
  }
    #season {
      margin-bottom: 0;
    }
  #call-link-div {
    text-align: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.03);
  }

  #call-today {
    text-decoration: none;
    color: #FFF;
    background-color: #006533;
    font-size: 1.1rem;
    border: none;
    font-weight: 700;
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
  }

  #find-us-text {
    text-align: center;
    font-size: 1.5rem;
  }

  #address-title {
    margin-bottom: 0;
  }

  #hours-title {
    margin-bottom: 0;
  }

  p{
    color: #000;
  }

  .active, .collapsible:hover {
    background-color: #00592d;
  }

  ul {
    list-style-type: none;
    padding: 0;
  }

  li {
    margin-top: .5rem;
    margin-bottom: 1.25rem;
    line-height: 22px;
    color: #000;
  }

  .content {
    padding: 0 18px;
    background-color: rgba(0, 0, 0, 0.03);
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.2s ease-out;
    font-size: 1rem;
  }

  .collapsible:after {
    content: '\02795'; /* Unicode character for "plus" sign (+) */
    font-size: 1rem;
    float: right;
    margin-top: 5%;
    color: transparent;
    text-shadow: 0 0 0 #FFF;
  }

  .active:after {
    content: "\2796"; /* Unicode character for "minus" sign (-) */
    margin-top: 5%;
  }
}
html {
  background-color: #cec79a;
  max-width: 100%;
}

body {
  background-color: #cec79a;
  min-height: 100%;
}
// navbar start
nav {
  background: #cec79a;

  #brand-anchor {

  }

  #location-container {
    max-width: 9%;

    img {
      max-width: 29px;
    }
  }

  #brand-container {
    max-width: 65%;

    img {
      min-width: 190px;
      max-width: 208px;

    }
  }

  #nav-logo {

  }

  .navbar-toggler,
  .navbar-toggler:focus, {
    border: transparent;
    box-shadow: none;
  }

  .icon-bar {
    background-color: #006533;
    width: 32px;
    height: 4px;
    display: block;
    border-radius: 2px;
    margin: 5px 0 0 0;
  }
//navbar links begin
  .navbar-collapse {

    .navbar-nav {

      .nav-item{

        .nav-link {
          font-size: 1.25rem;
          white-space: nowrap;
          color: #00602f;
          &:hover {
            color: #fff;
            text-decoration: underline;
          }

        }
      }
    }
  }



// hamburger menu expanded
  .show, .collapsing {
      .active {
        .nav-link {
          background-color: #00592d;
        }
      }
      .top {
        margin-top: 0;
      }

    .bottom {
      margin-bottom: 0;
      padding-bottom: 0;
    }

    .nav-link {
      padding-left: 1rem;
      background-color: #006533;
      color: #fff !important;
      font-size: 1rem;
      padding-top: 1rem;
      padding-bottom: 1rem;

      &:active {
        background-color: #00592d;
      }
    }
  }
}
// end of navbar
// start of media queries
@media only screen and (min-width: 992px) and (max-width: 1036px) {
  .nav-link {
    padding-left: 1em;
    padding-right: 1em;
    font-size: 1.17rem !important;
  }
}

//home page
#wrapper {
  background-color: #006533;
  height: 100%;
  width: 100%;
  #home-photo-container {
    background-image: url('https://s3.amazonaws.com/images.rvwholesalers.com/Indian+Hallow/assets/indian-hallow-pond6.JPG');
    background-size: 100%;
    background-position: center;
    height: 94vh;
    background-repeat: no-repeat;

    #p-div {
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
    }

    p {
      text-align: center;
      font-family: --font-sans-serif, sans-serif;
      font-size: 3.5rem;
      font-weight: 700;
      color: #FFF;
      text-shadow: 4px 4px 4px #000,
      4px 4px 4px #000;
    }
  }

    .scroll {
      position: absolute;
      bottom: 15px;
      left: 50%;
      right: 50%;
      vertical-align: bottom;
    }
    .scroll-button {
      min-width: 3rem;
      max-width: 3rem;
    }

  #first-scroll-position {
    visibility: hidden;
    position: absolute;
    top: 2px;
  }

  #second-scroll-position {
    visibility: hidden;
    position: absolute;
    top: 2px;
  }

  #third-scroll-position {
    visibility: hidden;
    position: absolute;
    top: 2px;
  }

  #home-intro {
    background-image: url(https://s3.amazonaws.com/images.rvwholesalers.com/Indian+Hallow/assets/indian-hallow-pool-2-watercolor.JPG);
    background-size: 100%;
    background-position: center;
    height: 101vh;
    text-align: center;
    position: relative;
  }
    #intro-text {
      max-width: 450px;
      right: 3%;
      bottom: 92%;
      top: 8%;
      position: absolute;

    p {
      color: #FFF;
      font-size: 2.5rem;
      text-shadow: 5px 5px 5px #000,
      5px 5px 5px #000;
    }
  }

  #amenities-container {
    background-image: url("https://s3.amazonaws.com/images.rvwholesalers.com/Indian+Hallow/assets/indian-hallow-pond-hero.jpg");
    background-size: 100%;
    background-position: center;
    height: 101vh;
    text-align: center;
    position: relative;

    ul {
      max-width: 500px;
      position: absolute;
      top: 5%;
      left: 8%;

      #amenities {
        font-size: 3rem;
      }
      li {
        color: #FFF;
        font-size: 2rem;
        text-shadow: 4px 4px 4px #000;

      }
    }
  }

  #find-us-home {
    background-image: url("https://s3.amazonaws.com/images.rvwholesalers.com/Indian+Hallow/assets/indian-hallow-sign3-edited.JPG");
    background-size: 100%;
    background-position: 0px -100px;
    height: 101vh;
    text-align: center;
    position: relative;

  #find-home-text {
    position: absolute;
    top: 57%;
    right: 5%;
  }

    #find-home-season {
      position: absolute;
      top: 10%;
      left: 5%;

      p {
        color: #FFF;
        font-size: 2.5rem;
        text-shadow: 4px 4px 4px #000,
        5px 5px 5px #000;;
      }
    }
  p {
    color: #FFF;
    font-size: 2rem;
    text-shadow: 4px 4px 4px #000,
    5px 5px 5px #000;;
  }
  }

  #find-us {
    background-color: #5A885A;
    border-radius: 10px;
    border-color: #000;
    border-style: solid;

    p {
      color: #FFF;
    }

    #find-us-text {
      text-align: center;
      font-size: 1.5rem;
      font-weight: 500;
    }

    #address-title {
      font-size: 1.1rem;
      font-weight: 500;
    }

    #address {
      font-size: 1rem;
    }

    #hours-title {
      font-size: 1.1rem;
      font-weight: 500;
    }

    #hours {

    }

    #season {

    }
     #call-link-div {
       text-align: center;
       margin-bottom: 0;
       border-radius: 20px;
       #call-today {
         border-radius: 20px;
         text-decoration: none;
         color: #FFF;
       }
     }
  }
}
//park rules
#rules {
  color: #000;
  background-color: #FFF;
}

//map of park

#map-container {
  background-color: rgb(234, 234, 234);
  overflow: hidden;
  text-align: center;
  margin-bottom: 0;
  #map-image {
    width: 85%;
    max-height: 690px;
    max-width: 532px;
    overflow: hidden;
    box-shadow: rgba(0, 0, 0, 0.35) 0 5px 15px;
    border-color: #cec79a;
    border-style: solid;
    border-width: 6px;
  }
}

#find-us-map {
  margin-top: -1px;
}

#find-us-map {
  background-color: rgb(234, 234, 234);
  text-align: center;
  #address-title {
    margin-bottom: 0;
  }

  #hours-title {
    margin-bottom: 0;
  }

  p {
    text-shadow: 1px 1px 3px #adadad;
    margin-left: 1rem;
    margin-right: 1rem;
  }
}
//contact
#contact-wrapper {
  background-color: #006533;
  height: 100%;
  width: 100%;
  #find-us {
    background-color: #5A885A;
    border-radius: 10px;
    height: 80%;
    border-color: #000;
    border-style: solid;
  }
}
#contact-container {
  background-color: #5A885A;
  border-radius: 10px;
  overflow: hidden;
  border-color: #000;
  border-style: solid;
}

#find-us-contact {
  background-color: rgb(234, 234, 234);
  overflow: visible;
  margin-top: -1px;
  text-align: center;

  #find-us-contact-align {
    display: inline-block;
    text-align: left;
  }

  p{
    font-size: 1rem;
    text-align: left;
  }

  #address-title {
    margin-bottom: 0;
  }

  #hours-title {
    margin-bottom: 0;
  }

  #season {
    text-align: center;
    font-size: 1rem;
    margin-bottom: .5rem;
    margin-left: 0.75rem;
    margin-right: 0.75rem;
  }
}

#google-container {
  background-color: rgb(234, 234, 234);
  text-align: center;
  overflow:visible;

  iframe {
    width: 90%;
    height: 50vw;
    max-height: 667px;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.35) 0 5px 15px;
    border-color: #d3cb9d;
    border-style: solid;
    border-width: 3px;
  }
}
//park-pics
#photo-wrapper {
  background-color: #5A885A;
  height: 100%;
  width: 100%;
}

#photo-container {
  background-color: #5A885A;
  overflow: hidden;
}

.photo-div {
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    background-attachment: fixed;
    min-height: 101vh;
  }

#sticky-footer {
  position: fixed;
  padding: 10px 10px 0 10px;
  bottom: 0;
  width: 100%;
  /* Height of the footer*/
  height: 101px;
  z-index: 1000;
  background-color: #cec79a;

  #copyright {
    white-space: nowrap;
  }
}

#sticky-nav {
  position: fixed;
  padding: 10px 10px 0 10px;
  top: 0;
  width: 100%;
  /* Height of the footer*/
  height: 67px;
  z-index: 1000
}
//footer
#footer-div {
  background-color: #cec79a;
}
#facebook {
  img{
  width: 32px;
  height: 32px;}
}

#footer-links {
  text-align: center;
  color: #4d3a2e;
}

#copyright {
  text-align: center;
  font-size:.92rem;
  color: #4d3a2e;
}

//media-queries
@media only screen and (min-width: 360px) and (max-width: 412px) and (min-height: 740px) and (max-height: 915px) {
  .scroll {
    bottom: 100px !important;
    }
  }

  @media only screen and (min-width: 375px) and (max-width: 430px) {
    #home-photo-container {
      background-size: 117% !important;
    }

    #home-intro {
      background-size: 126% !important;
    }

    #amenities-container {
      background-size: 126% !important;
    }

    #find-us-home {
      background-size: 127% !important;
    }
  }
  @media only screen and (max-width: 625px) {
    #contact-container {
      width: 100% !important;
    }
  }
  @media only screen and (max-width: 626px) {
    #find-us {
      width: 100%;
    }
  }

  @media only screen and (min-width: 913px) and (max-width: 991px) {
    #find-us-home {
      background-position: 0 0 !important;
    }
  }

  @media only screen and (min-width: 571px) and (max-width: 992px) {
  }

  @media only screen and (min-width: 570px) and (max-width: 779px) {
    #find-us-home {
      background-position: 0 -150px !important;
    }
  }

  @media only screen and (min-width: 780px) and (max-width: 912px) {
    #find-us-home {
      background-position: 0 -350px !important;
    }
  }
  @media only screen and (max-width: 570px) {
    #find-us-home {
      background-image: url("https://s3.amazonaws.com/images.rvwholesalers.com/Indian+Hallow/assets/Mobile/indian-hallow-sign3-edited.JPG");
    }
  }
  @media only screen and (max-width: 991px) {
    #play {
      background-position: 0 -45px;
    }

    #cabin-1 {
      background-position: 0 10px;
    }

    #main-pond {
      background-position: 51% -2%;
    }

    #play2 {
      background-position: 50% 0;
    }

    #pool-3 {
      background-position: 32% 0;
    }

    #pond-side {
      background-position: 0 -1000px;
    }

    #play {
      background-position: -220px 50px;
    }

    #pond-side {
      background-position: -250px 75px;
    }

    #sign {
      background-position: -150px 60px;
    }

    #cabin-view {
      background-position: -75px -75px;
    }

    #cornhole {
      background-position: -230px 0;
    }

    #cabin-1 {
      background-position: -80px 70px;
    }

    #cabin-2 {
      display: none;
    }

    #last {
      background-repeat: no-repeat;
      background-position: center center;
      background-size: cover;
      background-attachment: fixed;
      height: 97px;
    }

    #sticky-nav {
      #nav-logo {
        max-width: 212px;
      }
    }

    #find-us-map {
      .animated-button {
        display: none;
      }
    }

    #find-us-contact {
      .animated-button {
        display: none;
      }
    }
  }
  @media only screen and (min-width: 992px) {
    #home-photo-container {
      overflow: hidden;
      position: relative;
      display: block;
      width: 100%;
      bottom: 0;
    }

    .navbar-expand-lg {
      padding-top: 0 !important;
    }
    #find-home-hours-small, #find-home-text-small {
      display: none;
    }
    #brand-container {
      margin-left: 10px !important;
    }

    .dropdown {
      margin-right: 2rem;
    }

    .nav-link {
      padding-left: 1em;
      padding-right: 1em;
    }

    #sticky-footer {
      position: relative;
    }

    #find-home-text-small, #find-home-hours-small {
      display: none;
    }

    #p-div {
      display: flex;
      justify-content: center;
      background-color: transparent !important;
    }

    #home-intro {


      p {

      }
    }
    .photo-div {
      background-repeat: no-repeat;
      background-position: center center;
      background-size: cover;
      background-attachment: fixed;
      height: 45vw;
    }

    #last {
      display: none;
    }

    #sticky-nav {
      #nav-logo {
        max-width: 255px;
      }

      .show {
        min-width: 100% !important;
      }

      ul {
        min-width: 100%;
      }
    }

    body {

      text-align: center;
    }

    #rules {
      .collapsible:after {
        vertical-align: center;
        margin-top: 1%;
      }

      button {
        max-height: 80px;
      }
    }


    #find-us {
      #find-us-text {
        font-size: 2rem;
      }

      p {
        font-size: 1.6rem;
        margin-left: 1rem;
        margin-right: 1rem;
        text-align: center;
        font-weight: 400;
      }

      #season {
        font-size: 2rem;
        font-weight: 500;
      }
    }

    .animated-button {
      max-width: 800px;
    }

    #map-container {
      float: left;
    }

    #find-us-map {
      padding-top: 80px;

      p {
        font-size: 2vw;
      }
    }

    #find-us-contact {
      #find-us-contact-align {
        margin-top: 5vw;
      }

      p {
        font-size: 1.5rem;
      }

      #season {
        font-size: 1.5rem;
      }

      #call-link-div {
        .animated-button {
          margin-bottom: 1rem;
          font-size: 1rem;
          font-weight: 600;
        }
      }
    }

    #google-container {
      iframe {
        border-radius: 0;
      }
    }
  }

  @media only screen and (min-width: 1060px) and (max-width: 1232px) {
    #find-us-contact-align {
      margin-top: 6.5vw !important;
    }
  }

  @media only screen and (min-width: 1233px) and (max-width: 1500px) {
    #find-us-contact-align {
      margin-top: 8.5vw !important;
    }
  }

  @media only screen and (min-width: 1501px) {
    #find-us-contact-align {
      margin-top: 7.5vw !important;
    }
  }


  @media only screen and (max-width: 1300px) and (min-width: 913px) {

    #find-home-hours-small, #find-home-text-small {
      display: none;
    }

    #wrapper {
      background-color: #006533;
      height: 100%;
      width: 100%;

      #home-photo-container {
        background-image: url('https://s3.amazonaws.com/images.rvwholesalers.com/Indian+Hallow/assets/Medium/indian-hallow-pond6.JPG');
        background-size: 100%;
        background-position: center;
        height: 94vh;

        #p-div {
          border-top-left-radius: 10px;
          border-top-right-radius: 10px;
        }


        p {
          text-align: center;
          font-family: --font-sans-serif, sans-serif;
          font-size: 3.5rem;
          font-weight: 700;
          color: #FFF;
          text-shadow: 4px 4px 4px #000,
          4px 4px 4px #000;
        }
      }

      #intro-text {
        max-width: 300px;
        left: 35%;
        top: 32%;
        position: absolute;

        p {
          color: #FFF;
          font-size: 1.5rem;
          text-shadow: 5px 5px 5px #000,
          5px 5px 5px #000;
        }
      }

      #find-us-home {
        background-image: url("https://s3.amazonaws.com/images.rvwholesalers.com/Indian+Hallow/assets/Medium/indian-hallow-sign3-edited.JPG");
        background-size: 100%;
        background-position: center;
        height: 101vh;
        text-align: center;
        position: relative;

        #find-home-season {
          position: absolute;
          top: 10%;
          left: 5%;

          p {
            color: #FFF;
            font-size: 2.5rem;
            text-shadow: 4px 4px 4px #000,
            5px 5px 5px #000;
          }
        }
      }
    }
    #find-home-hours {
      position: absolute;
      top: 10%;
      left: 40%;
      width: 533px;

      p {

      }
    }
  }

//noinspection CssNoGenericFontName
@media only screen and (max-width: 912px) {
    #wrapper {
      background-color: #006533;
      height: 100%;
      width: 100%;

      #home-photo-container {
        background-image: url('https://s3.amazonaws.com/images.rvwholesalers.com/Indian+Hallow/assets/Mobile/indian-hallow-pond6.JPG');
        background-size: 100%;
        background-position: center;
        height: 94vh;

        #p-div {
          border-top-left-radius: 10px;
          border-top-right-radius: 10px;
        }

        p {
          text-align: center;
          font-family: --font-sans-serif, sans-serif;
          font-size: 1.2rem;
          font-weight: 700;
          color: #FFF;
          text-shadow: 4px 4px 4px #000,
          4px 4px 4px #000;
          margin-left: 1%;
          margin-right: 1%;
          padding-top: 2%;
        }
      }

      .scroll {
        position: absolute;
        bottom: 15px;
        left: 44%;
        right: 56%;
        vertical-align: bottom;
      }

      #home-intro {
        background-image: none;
        background-size: 130%;
        background-position: center;
        height: 101vh;
        text-align: center;
        position: relative;

        &.visible {
          background-image: url("https://s3.amazonaws.com/images.rvwholesalers.com/Indian+Hallow/assets/Mobile/indian-hallow-pool-2-watercolor.JPG");
        }
      }

      #intro-text {
        max-width: 200px;
        left: 25%;
        top: 32%;
        position: absolute;

        p {
          color: #FFF;
          font-size: 1rem;
          text-shadow: 5px 5px 5px #000,
          5px 5px 5px #000;
        }
      }

      #amenities-container {
        background-image: none;
        background-size: 100%;
        background-position: center;
        height: 101vh;
        text-align: center;
        position: relative;

        &.visible {
          background-image: url("https://s3.amazonaws.com/images.rvwholesalers.com/Indian+Hallow/assets/Mobile/indian-hallow-pond-hero.jpg");
        }

        ul {
          max-width: 200px;
          position: absolute;
          top: 15%;
          left: 1%;

          #amenities {
            font-size: 1.1rem;
          }

          li {
            color: #FFF;
            font-size: 0.77rem;
            text-shadow: 4px 4px 4px #000;

          }
        }
      }

      #find-us-home {
        background-image: none;
        background-size: 100%;
        background-position: center 2px;
        height: 101vh;
        text-align: center;
        position: relative;

        &.visible {
          background-image: url("https://s3.amazonaws.com/images.rvwholesalers.com/Indian+Hallow/assets/Mobile/indian-hallow-sign3-edited.JPG");
        }

        #find-home-text-small {
          position: absolute;
          top: 65%;
          right: 1vw;
          text-align: right;
        }

        #find-us-text-small {
          margin-bottom: 0;
        }

        #find-home-season {
          position: absolute;
          top: 5%;
          left: 0;

          p {
            color: #FFF;
            font-size: 1.1rem;
            font-weight: 500;
            text-shadow: 4px 4px 4px #000,
            5px 5px 5px #000;
            line-height: 2;
          }
        }

        p {
          color: #FFF;
          font-size: 1rem;
          text-shadow: 4px 4px 4px #000,
          5px 5px 5px #000;
        }
      }
    }

    #find-home-hours-small {
      position: absolute;
      top: 80%;
      left: 1vw;
      text-align: left;

      #hours-title-small {
        margin-bottom: 0;
      }

      p {
        color: #FFF;
        font-size: 1rem;
        text-shadow: 4px 4px 4px #000,
        5px 5px 5px #000;;
      }
    }

    #find-home-text, #find-home-hours {
      display: none;
    }
  }


  *, *:before, *:after {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }


  .mouse_scroll {
    display: block;
    width: 24px;
    height: 90px;
    bottom: 40vh;
    position: fixed;
    right: 1vw;
    background-color: rgba(0, 0, 0, 0.3);
    border-radius: 15px;
  }


  .m_scroll_arrows {
    display: block;
    -ms-transform: rotate(45deg); /* IE 9 */
    -webkit-transform: rotate(45deg); /* Chrome, Safari, Opera */
    transform: rotate(45deg);

    border-right: 2px solid white;
    border-bottom: 2px solid white;
    margin: 0 0 3px 4px;

    width: 16px;
    height: 16px;
  }


  .unu {
    margin-top: 1px;
  }

  .unu, .doi, .trei {
    -webkit-animation: mouse-scroll 1s infinite;
    -moz-animation: mouse-scroll 1s infinite;
    animation: mouse-scroll 1s infinite;

  }

  .unu {
    -webkit-animation-delay: 0.1s;
    -moz-animation-delay: 0.1s;
    -webkit-animation-direction: alternate;

    animation-direction: alternate;
    animation-delay: 0.1s;
  }

  .doi {
    -webkit-animation-delay: .2s;
    -moz-animation-delay: .2s;
    -webkit-animation-direction: alternate;

    animation-delay: .2s;
    animation-direction: alternate;

    margin-top: -6px;
  }

  .trei {
    -webkit-animation-delay: .3s;
    -moz-animation-delay: .3s;
    -webkit-animation-direction: alternate;

    animation-delay: .3s;
    animation-direction: alternate;


    margin-top: -6px;
  }

  .mouse {
    height: 42px;
    width: 24px;
    border-radius: 14px;
    transform: none;
    border: 2px solid white;
    top: 170px;
  }

  .wheel {
    display: block;
    margin: 5px auto;
    background: white;
    position: relative;

    height: 4px;
    width: 4px;
    border: 2px solid #fff;
    -webkit-border-radius: 8px;
    border-radius: 8px;
  }

  .wheel {
    -webkit-animation: mouse-wheel 0.6s linear infinite;
    -moz-animation: mouse-wheel 0.6s linear infinite;
    animation: mouse-wheel 0.6s linear infinite;
  }

  @-webkit-keyframes mouse-wheel {
    0% {
      opacity: 1;
      -webkit-transform: translateY(0);
      -ms-transform: translateY(0);
      transform: translateY(0);
    }

    100% {
      opacity: 0;
      -webkit-transform: translateY(6px);
      -ms-transform: translateY(6px);
      transform: translateY(6px);
    }
  }
  @-moz-keyframes mouse-wheel {
    0% {
      top: 1px;
    }
    25% {
      top: 2px;
    }
    50% {
      top: 3px;
    }
    75% {
      top: 2px;
    }
    100% {
      top: 1px;
    }
  }
  @-o-keyframes mouse-wheel {

    0% {
      top: 1px;
    }
    25% {
      top: 2px;
    }
    50% {
      top: 3px;
    }
    75% {
      top: 2px;
    }
    100% {
      top: 1px;
    }
  }
  @keyframes mouse-wheel {

    0% {
      top: 1px;
    }
    25% {
      top: 2px;
    }
    50% {
      top: 3px;
    }
    75% {
      top: 2px;
    }
    100% {
      top: 1px;
    }
  }

  @-webkit-keyframes mouse-scroll {

    0% {
      opacity: 0;
    }
    50% {
      opacity: .5;
    }
    100% {
      opacity: 1;
    }
  }
  @-moz-keyframes mouse-scroll {

    0% {
      opacity: 0;
    }
    50% {
      opacity: .5;
    }
    100% {
      opacity: 1;
    }
  }
  @-o-keyframes mouse-scroll {

    0% {
      opacity: 0;
    }
    50% {
      opacity: .5;
    }
    100% {
      opacity: 1;
    }
  }
  @keyframes mouse-scroll {

    0% {
      opacity: 0;
    }
    50% {
      opacity: .5;
    }
    100% {
      opacity: 1;
    }
  }
